import payload_plugin_qLmFnukI99 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jozdw60ZsE from "/usr/src/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import titles_dw2T9lEw4h from "/usr/src/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/usr/src/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/usr/src/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import api_GFfDXud5Cr from "/usr/src/app/plugins/api.ts";
import maska_UHaKf2z1iQ from "/usr/src/app/plugins/maska.ts";
import posthog_client_fUucxKWhZE from "/usr/src/app/plugins/posthog.client.ts";
import twMerge_AFcdbGiGv8 from "/usr/src/app/plugins/twMerge.ts";
import utils_EEhlE5KdlH from "/usr/src/app/plugins/utils.ts";
import defaults_aRl2YLnTcb from "/usr/src/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_Jozdw60ZsE,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  api_GFfDXud5Cr,
  maska_UHaKf2z1iQ,
  posthog_client_fUucxKWhZE,
  twMerge_AFcdbGiGv8,
  utils_EEhlE5KdlH,
  defaults_aRl2YLnTcb
]