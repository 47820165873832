import { defineStore } from 'pinia'
import { UserRole, type IUser } from '~/types/models/user.interface'

export const getRoleBasedRedirect = (role?: UserRole): string => {
	const redirectMap: Record<UserRole, string> = {
		[UserRole.STREAMER]: '/profile/dashboard',
		[UserRole.ADMIN]: '/admin',
		[UserRole.USER]: '/join',
	}

	return role ? redirectMap[role] : '/streamers'
}

export const useUserStore = defineStore('user', {
	state: () =>
		({
			user: null,
		} as { user: IUser | null }),
	getters: {
		isLoggedIn: state => !!state.user,
	},
	actions: {
		login(user: IUser) {
			this.user = user
		},
		logout() {
			this.user = null
		},
		async updateAsset(asset: keyof IUser['assets'], value: string) {
			if (this.user) {
				await useApi(`/streamers/${this.user._id}/update-assets`, {
					method: 'POST',
					body: {
						[asset]: value,
					},
				})

				this.user.assets[asset] = value
			}
		},
	},
})
